.presentation {
    max-width: 800px;
    margin-left: 10%;
    margin-right: 10%;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  h1, h2, h3, p, ul {
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
  }
  
  h2 {
    font-size: 1.8em;
  }
  
  h3 {
    font-size: 1.4em;
    margin-top: 30px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  strong {
    font-weight: bold;
  }
  
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }