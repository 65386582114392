.github-repos-container {
    padding-bottom: 2rem; /* Ajusta este valor según necesites */
    padding-left: 5%;
  }
  
  .repo-list {
    padding: 0;
    padding-left: 10%;
  }
  
  .repo-list li {
    margin-bottom: 0.5rem;
  }
  
  .repo-list a {
    color: #0366d6; /* Color típico de los enlaces de GitHub */
    text-decoration: none;
  }
  
  .repo-list a:hover {
    text-decoration: underline;
  }