.image-container {
  width: 95%;
  max-width: 95%;
  margin: 0 auto;
  overflow: hidden; /* Asegura que nada se desborde */
  /*width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
  margin-bottom: 20px;
  padding-top: 0px;
  margin-top: 0px;
  color: #fff;*/
}
  
.image-wrapper {
  width: 100%;
  height: 550px; /* Ajusta esta altura según tus necesidades */
  position: relative;
  overflow: hidden;
}
  
.centered-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  display: block;
}

.header-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

@media screen and (min-width: 768px) {
  .header-nav {
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
  }
}