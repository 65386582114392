.programming-languages {
    padding: 20px;
    padding-left: 5%;
  }
  
  .language-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .language-item {
    text-align: center;
  }
  
  .language-icon {
    width: 50px;
    height: 50px;
  }
  
  .proficiency-bar {
    background-color: #e0e0e0;
    height: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .proficiency-fill {
    background-color: #4caf50;
    height: 100%;
    border-radius: 5px;
  }