.social-links-section{
  background: #2f3640;
  padding-top: 0.5%;
}

.social-links-title{
    display: flex;
    justify-content: center;
}

.social-links {
    display: flex;
    justify-content: center;
  }

  .social-links img {
    width: 40px; /* Cambia este valor al tamaño deseado */
    height: 40px; /* Cambia este valor al tamaño deseado */
    padding-left: 2%;
  }