/* ContactForm.css */

.form-container {
    max-width: 600px; /* Ancho máximo del formulario */
    margin: 0 auto; /* Centrar el formulario */
    padding: 20px; /* Espaciado interno */
    background-color: #f9f9f9; /* Color de fondo */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    margin-top: 2%;
    margin-bottom: 5%;
  }
  
  .form-container h2 {
    text-align: center; /* Centrar el título */
    margin-bottom: 20px; /* Espaciado inferior */
    color: #262626;
  }
  
  .form-container input,
  .form-container textarea {
    width: 96%; /* Ancho completo */
    padding: 10px; /* Espaciado interno */
    margin-bottom: 15px; /* Espaciado inferior entre campos */
    border: 1px solid #ccc; /* Borde gris claro */
    border-radius: 4px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
  }
  
  .form-container input:focus,
  .form-container textarea:focus {
    border-color: #007bff; /* Color del borde al enfocar */
    outline: none; /* Sin contorno al enfocar */
  }
  
  .form-container button {
    width: 100%; /* Ancho completo del botón */
    padding: 10px; /* Espaciado interno */
    background-color: #262626; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
    cursor: pointer; /* Cambiar a puntero al pasar el ratón */
  }
  
  .form-container button:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el ratón */
  }

  .error-message {
    color: red;
    font-size: 0.8em;
    padding-bottom: 1px;
    text-align: justify;
    display: flex;
  }