.logo-container {
  width: 120px;
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}

.logo-wrapper {
  width: 120px;
  height: 120px; /* Default height */
  position: relative;
  overflow: hidden;
}

.centered-logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}

/* For small screens (centered and larger logo) */
@media screen and (max-width: 767px) {
  .logo-container {
    margin: 0 auto; /* Center the logo */
    width: 150px; /* Increase width for small screens */
    max-width: none; /* Remove max-width restriction */
  }

  .logo-wrapper {
    width: 150px; /* Adjust width for logo wrapper */
    height: 150px; /* Adjust height for logo wrapper */
  }
}

/* For large screens (15% from left) */
@media screen and (min-width: 768px) {
  .logo-container {
    margin-left: 5%; /* Position logo at 15% from left */
    margin-right: auto;
  }
}